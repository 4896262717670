'use client';
import { LocalStorageService } from '@/shared/utils/local-storage';
import { useSearchParams } from 'next/navigation';
import React, {createContext, ReactNode, useEffect} from 'react';
import {AITracksDefault, useAnalytics} from "@/features/analytics/AI/AITracks";
import {AnalyticsPageTypes} from "@/app.model";
import {IModularPage} from "@/shared/api/pages";
type TProps = Readonly<{ classnames?: string; children: ReactNode, aiPageType: AnalyticsPageTypes, pageData?: IModularPage }>;

export const AnalyticsContext = createContext<{AITracks: any, AIReadyAsDep: boolean}>({AITracks: AITracksDefault, AIReadyAsDep: false});
const AnalyticsContextProvider = AnalyticsContext.Provider;

const Layout = (props: TProps) => {
    const { classnames, children, aiPageType, pageData } = props;
    const searchParams = useSearchParams();
    const isSentryQp = searchParams.get('evo:sentry_test') === 'true';
    const isSentryLs = LocalStorageService.getItem('sentry_test') === 'true';
    const pageType = aiPageType ?? AnalyticsPageTypes.UNKNOWN;
    const [AITracks, AIReadyAsDep] = useAnalytics(pageData, pageType);

    useEffect(() => {
        AIReadyAsDep && AITracks.PageView();
    }, [AIReadyAsDep]);

    useEffect(() => {
        handleAccessibilityEnterKeyForFocused();
        function handleAccessibilityEnterKeyForFocused(turnOff = false) {
            window?.[!turnOff ? 'addEventListener' : 'removeEventListener']?.('keydown', accessibilityEnterKeyForFocusedHandler);
        }
        function accessibilityEnterKeyForFocusedHandler(ev: Event) {
            if ((ev as any)?.key === 'Enter') {
                const focusedElement: any = document?.activeElement;
                if (focusedElement?.id === 'sidebarToggle') { // input checkbox, e.g. burger
                    focusedElement.checked = !focusedElement.checked;
                    focusedElement.setAttribute('checked', focusedElement.checked ? 'false' : 'true');
                    focusedElement.focus();
                } else if (focusedElement) { // links, buttons, cards, etc.
                    focusedElement?.click?.();
                }
            }
        }
        return () => {
            handleAccessibilityEnterKeyForFocused(true);
        }
    }, []);

    const handleButtonClick = () => {
        fetch('/api/sentry?throw=true');
        throw new Error('test sentry frontend error ' + Math.random().toFixed(10));
    };
    return (
        <>
            <AnalyticsContextProvider value={{AITracks, AIReadyAsDep}}>
                <div>
                    {(isSentryLs || isSentryQp) && (
                        <button style={{ marginLeft: '50%' }} onClick={handleButtonClick}>
                            test sentry frontend error
                        </button>
                    )}
                    <main className={classnames}>{children}</main>
                </div>
            </AnalyticsContextProvider>
        </>
    );
};

export default Layout;
