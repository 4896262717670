import React from 'react';

import { TPill } from './model';
import Link from 'next/link';

export const Pill = (props: TPill) => {
    const { pillType, prefix, label, selected, onClick, href, target, leftImg, rightImg } = props;
    const id = `pill-title-${prefix}-${label}`;

    function getCustomStyles() {
        return {
            ...(props?.override_border_color && { '--border-color': props.override_border_color }),
            ...(props?.override_label_color && { '--label-color': props.override_label_color }),
            ...(props?.override_background_color && { '--background-color': props.override_background_color }),
            ...(props?.override_border_color_hover && { '--border-color-hover': props.override_border_color_hover }),
            ...(props?.override_label_color_hover && { '--label-color-hover': props.override_label_color_hover }),
            ...(props?.override_background_color_hover && {
                '--background-color-hover': props.override_background_color_hover,
            }),
            ...(props?.override_border_color_focus && { '--border-color-focus': props.override_border_color_focus }),
            ...(props?.override_label_color_focus && { '--label-color-focus': props.override_label_color_focus }),
            ...(props?.override_background_color_focus && {
                '--background-color-focus': props.override_background_color_focus,
            }),
            ...(props?.override_border_color_active && { '--border-color-active': props.override_border_color_active }),
            ...(props?.override_label_color_active && { '--label-color-active': props.override_label_color_active }),
            ...(props?.override_background_color_active && {
                '--background-color-active': props.override_background_color_active,
            }),
            '--left-padding': props?.leftImg ? '3rem' : '1rem',
            '--right-padding': props?.rightImg ? '3rem' : '1rem',
        };
    }

    // Function to create the input element based on pillType
    const createInputElement = () => {
        switch (pillType) {
            case 'radio':
                return <input id={id} name={prefix} type="radio" checked={selected} value={label} />;
            case 'checkbox':
                return <input id={id} name={prefix} type="checkbox" checked={selected} value={label} />;
            case 'button':
                return (
                    <button id={id} type="button" onClick={onClick}>
                        {label}
                    </button>
                );
            case 'anchor':
                return (
                    <Link id={id} href={href? href: '#'} target={target}>
                        {label}
                    </Link>
                );
            default:
                return <input id={id} name={prefix} type="radio" checked={selected} value={label} />;
        }
    };

    // Generate the input element
    const inputElement = createInputElement();

    return (
        <label
            key={id}
            className="ark-ui-pill text-16"
            htmlFor={id}
            data-label={label}
            aria-label={label}
            style={getCustomStyles() as React.CSSProperties}
        >
            {leftImg && <img className="left-img" src={leftImg} alt={label} />}
            {inputElement}
            {rightImg && <img className="right-img" src={rightImg} alt={label} />}
        </label>
    );
};
